import React from "react";

import "./index.css";
import { getStorageValue } from "../../helpers/storage.helpers";
import ReduxState from "../../redux/state";
import { getMatchOpponents } from "../../helpers/match";
import { connect } from "react-redux";

interface LivePlayersStore {
  home: string;
  away: string;
}

interface LivePlayersProp {
  arrowHome: any;
  arrowAway: any;
}

type LivePlayersProps = LivePlayersStore & LivePlayersProp;

const connector = (state: ReduxState): LivePlayersStore => {
  const { match } = state;
  if (!match) {
    return {
      home: "PLAYER 1",
      away: "PLAYER 2",
    };
  }
  const [home, away] = getMatchOpponents(match.name);
  return { home, away };
};

export default connect(connector)(function LivePlayers(
  props: LivePlayersProps
) {
  const backgroundColor = getStorageValue("sponsor-color-background");
  const backgroundGradient = `${backgroundColor}`;
  const backgroundFilter = `drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))`;
  const backgroundFilterBackdrop = `blur(20px)`;

  const innerStyle = {
    // background: backgroundGradient,
    // filter: backgroundFilter,
    // backDrop: backgroundFilterBackdrop
  };

  const getInitials = function (string: any) {
    let names = string.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();
    
    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};

  return (
    <div className="onyx-live-players-container" style={innerStyle}>
      <div>{props.arrowHome}&nbsp;{getInitials(props.home)}</div>
      <div>{props.arrowAway}&nbsp;{getInitials(props.away)}</div>
    </div>
  );
});
