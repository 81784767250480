import React from "react";

export default function Player() {
  return (
    <iframe
      title="player"
      src="https://player.twitch.tv/?channel=odeaj&parent=sendbox.onyxmatch.com"
      frameBorder="0"
      allowFullScreen
      scrolling="no"
      height="100%"
      width="100%"
    ></iframe>
  );
}
