import React, {useEffect, useState} from "react";
import "./index.css";
import logoImg from '../../../assets/logoHead.png'
import searchImg from '../../../assets/searchHead.png'
import linksImg from '../../../assets/linksHead.png'
import {BrowserRouter as Router, NavLink, Route, Switch, useLocation} from 'react-router-dom'
import {Login} from "../../Auth/Login";
import {hasAuthParams, loginError} from "@auth0/auth0-react/src/utils";

export default function Appbar() {
  const location = useLocation();
  const [isToken, setIsToken] = useState(false);

  const links = [{nameLink: 'Home', id: 1}, {nameLink: 'Sports', id: 2}, {nameLink: 'Events', id: 3}, {nameLink: 'OnDemand', id: 4}, {nameLink: 'FAQs', id: 5}, {nameLink: 'Login', id: 6}, {nameLink: 'Subscribe', id: 7}];
  const loginLinks = [{nameLink: 'Home', id: 1}, {nameLink: 'Sports', id: 2}, {nameLink: 'Events', id: 3}, {nameLink: 'OnDemand', id: 4}, {nameLink: 'FAQs', id: 5}, {nameLink: 'Subscribe', id: 7}];

  useEffect(() => {
    const isLogin = hasToken();
    setIsToken(isLogin);
  }, [location]);

  const hasToken = () => {
    return !!localStorage.getItem("onyxadmin.pid");
  };

  const getLinks = () => {
    if(isToken) {
      return loginLinks
    } {
      return links
    }
  };

  const getLink = (id: number) => {
    if(id === 6) {
      return '/login'
    } else {
      return '/'
    }
  };

  return (
    // <header className="main-header">
    //   <div className="header-left-wrapper">
    //     <div className="header-logo-wrapper">
    //       <img src="main-logo.png" className="main-logo" />
    //     </div>
    //     <ul className="main-menu-list">
    //       <li className="menu-item">
    //         <a className="menu-link">Home</a>
    //       </li>
    //       <li className="menu-item">
    //         <a className="menu-link">Schedule</a>
    //       </li>
    //       <li className="menu-item">
    //         <a className="menu-link menu-link--arrow menu-link--sports">
    //           Sports
    //           <ul className="side-menu-list">
    //             <li>
    //               <span className="menu-link">MMA</span>
    //             </li>
    //             <li>
    //               <span className="menu-link">BOX</span>
    //             </li>
    //             <li>
    //               <span className="menu-link">FOOTBALL</span>
    //             </li>
    //           </ul>
    //         </a>
    //       </li>
    //     </ul>
    //   </div>
    //   <div className="header-right-wrapper">
    //     <div className="side-menu-container">
    //       <a className="menu-link menu-link--arrow menu-link--right">
    //         Menu
    //         <ul className="side-menu-list">
    //           <li>
    //             <span className="menu-link">Menu item</span>
    //           </li>
    //           <li>
    //             <span className="menu-link">Menu item</span>
    //           </li>
    //           <li>
    //             <span className="menu-link">Menu item</span>
    //           </li>
    //         </ul>
    //       </a>
    //     </div>
    //     <img className="search-icon" src="search.png" />
    //   </div>
    // </header>
    <header className='header-wrapper'>
      <div className='header-inner'>
        <div className="header-logo">
          <img alt='logo' src={logoImg} />
        </div>
        <ul className={isToken ? "header-login-links" : "header-links"}>
          {
            getLinks().map(link => <li className="header-link" key={link.id}>
              <Router>
                <Switch>
                  <Route exact path="/login" component={Login} />
                    <NavLink to={getLink(link.id)} className="nav-link" onClick={() => console.log('link @@@@@@@@@@@: ', link)}>
                      {link.nameLink}
                    </NavLink>
                </Switch>
              </Router>
            </li>)
          }
        </ul>
        <div className="header-search">
          <img alt='searchImg' src={searchImg} />
        </div>
      </div>
    </header>
  );
}
