import React from "react";
import "./index.css";

export interface ChartsMenuProps {
  setStep: (step: number) => void;
  setIsBigWidget: (isBigWidget: boolean) => void;
  isBigWidget: boolean;
  toggleConvertOdds: boolean;
  toggleZoomOdds: boolean;
  showSmallCharts: boolean;
  setShowSmallCharts: (showSmallCharts: boolean) => boolean;
  setToggleConvertOdds: (toggleConvertOdds: boolean) => boolean;
  setToggleZoomOdds: (toggleZoomOdds: boolean) => boolean;
}
export default function ChartsMenu(props: ChartsMenuProps) {
  return (
    <div className="onyx-live-toggle-wrapper">
      <div
        className="onyx-live-toggle onyx-live-toggle-1"
        onClick={() => props.setStep(0)}
      />
      <div
        className="onyx-live-toggle onyx-live-toggle-2"
        onClick={() => props.setStep(1)}
      />
      <div
        className="onyx-live-toggle onyx-live-toggle-3"
        onClick={() => props.setShowSmallCharts(!props.showSmallCharts)}
      />
      <div
        className="onyx-live-toggle onyx-live-toggle-4"
        onClick={() => props.setToggleConvertOdds(!props.toggleConvertOdds)}
      />
      <div
        className="onyx-live-toggle onyx-live-toggle-5"
        onClick={() => props.setToggleZoomOdds(!props.toggleZoomOdds)}
      />
    </div>
  );
}
