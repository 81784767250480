import React, { useState, useEffect } from "react";
import { decimalToODDS, getOddDirection } from "../../../helpers/odds.helpers";
import ReduxState from "../../../redux/state";
import { pick } from "lodash";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import { getStorageValue } from "../../../helpers/storage.helpers";
import "./index.css";
import SmallChartsOdd from "../SmallChartsOdd";

const oddsConverter = require('odds-converter')

interface SmallChartsOwnProps {
  names: any;
  toggleConvertOdds: boolean;
  setInfoToggle: (infoToggle: boolean) => boolean;
  match: any;
  odds: any;
  infoToggle: boolean;
}

type SmallChartsStore = Pick<ReduxState, "lastOdds">;

type SmallChartsProps = SmallChartsOwnProps & SmallChartsStore;
const connector = (state: ReduxState): SmallChartsStore =>
  pick(state, ["lastOdds"], ["match"], ["odds"]);

const withStore = connect(connector);

// @ts-ignore
let currentTimeOut;
export default withStore(function SmallCharts(props: SmallChartsProps) {
  const { lastOdds, names, toggleConvertOdds, setInfoToggle, match, odds, infoToggle } = props;

  const [visableWidget, setVisableWidget] = useState('none')
  const players = match?.name.split(' v ');

  const getInitials = function (string: any) {
    let names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };
  const playerOneName = players && players[0] ? getInitials(players[0]) : '';
  const playerTwoName = players && players[1] ? getInitials(players[1]) : '';

  const backgroundColor = getStorageValue("sponsor-color-background");
  const backgroundGradient = backgroundColor && backgroundColor !== 'null' ? `${backgroundColor}` : "linear-gradient(167.63deg, #353535 -8.9%, rgba(51, 51, 51, 0) 111.79%)";
  const backgroundFilter = 'drop-shadow(0px 2.01667px 2.01667px rgba(0, 0, 0, 0.25))'

  function handleChangeVisableSetTimeout() {
    currentTimeOut = setTimeout(() => setVisableWidget('none'), 5000);
  }

  function myStopFunction() {
    // @ts-ignore
    clearTimeout(currentTimeOut);
  }

  const handleChangeVisable = () => {
    myStopFunction()
    infoToggle ? setVisableWidget('flex') : handleChangeVisableSetTimeout();
  }

  useEffect(() => {
    handleChangeVisable()
  }, [infoToggle]);

  const innerStyle = {
    background: backgroundGradient,
    filter: backgroundFilter,
    display: visableWidget,
  };

  const handleClose = () => {
    setInfoToggle(false)

  }

  const lastTimeStamp = odds && odds[odds.length - 1]?.timestamp
  const lastBeforeTimeStamp = odds && odds[odds.length - 2]?.timestamp

  const timestampsDiff = (curr: any, prev: any) => {
    let ms_Min = 60 * 1000;
    let ms_Hour = ms_Min * 60;
    let ms_Day = ms_Hour * 24;
    let ms_Mon = ms_Day * 30;
    let ms_Yr = ms_Day * 365;
    let diff = curr - prev;

    if (diff < ms_Min) {
      return Math.round(diff / 1000) + ' seconds ago';

    } else if (diff < ms_Hour) {
      return Math.round(diff / ms_Min) + ' minutes ago';

    } else if (diff < ms_Day) {
      return Math.round(diff / ms_Hour) + ' hours ago';

    } else if (diff < ms_Mon) {
      return 'Around ' + Math.round(diff / ms_Day) + ' days ago';

    } else if (diff < ms_Yr) {
      return 'Around ' + Math.round(diff / ms_Mon) + ' months ago';
    } else {
      return 'Around ' + Math.round(diff / ms_Yr) + ' years ago';
    }
  }

  return (
    <motion.div drag className="widget-wrapper" style={innerStyle}
      animate={{ opacity: !infoToggle ? [1, 0] : [0, 1] }}
      transition={{ duration: 4.5 }}
    >
      <div className='onyx-main-popup-inner-top-right_text-widget'>
        {lastTimeStamp && lastBeforeTimeStamp && (
          <p>UPDATES {timestampsDiff(lastTimeStamp, lastBeforeTimeStamp)}</p>
        )} 
      </div>
      <div className="widget-main">
        <SmallChartsOdd type='home' oddsAll={odds} name={playerOneName} odds={lastOdds && toggleConvertOdds ? oddsConverter.american.toDecimal(lastOdds.home) : lastOdds ? lastOdds.home : 0} />
        <SmallChartsOdd type='away' oddsAll={odds} name={playerTwoName} odds={lastOdds && toggleConvertOdds ? oddsConverter.american.toDecimal(lastOdds.away) : lastOdds ? lastOdds.away : 0} />
      </div>
      <div className="widget-close" onClick={handleClose}>
        <svg width="34" height="34" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.9">
            <line x1="6.17081" y1="1.36552" x2="1.40457" y2="6.13176" stroke="white" stroke-width="1.20383" stroke-linecap="round" stroke-linejoin="round" />
            <line x1="0.601914" y1="-0.601914" x2="7.3424" y2="-0.601914" transform="matrix(0.707107 0.707107 0.707107 -0.707107 1.35565 0.514282)" stroke="white" stroke-width="1.20383" stroke-linecap="round" stroke-linejoin="round" />
          </g>
        </svg>
      </div>
    </motion.div>

  );
});
