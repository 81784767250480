import React from "react";

import "./Clock.css";

export interface ClockProps {
  time: number | null;
}

export default function Clock(props: ClockProps) {
  const [ticks, setTicks] = React.useState(0);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setTicks(ticks + 1);
    }, 1000);
    return () => clearTimeout(timer);
  }, [ticks]);

  let HMS = "00:00:00";

  if (props.time !== null) {
    const diff = Date.now() - props.time;

    HMS = new Date(diff).toISOString().substr(11, 8);
  }

  return (
    <div className="Clock">
      <div className="Clock-digits">{HMS}</div>
    </div>
  );
}
