import ReduxState from "../state";
import { setLastOddsAction } from "../actions";
import { ReduxActionType } from "../types";
import { decimalToODDS } from "../../helpers/odds.helpers";
import { random } from "lodash";

type State = ReduxState["odds"];

type Actions = ReturnType<typeof setLastOddsAction>;

const initialState: State = [];

export default function oddsReducer(
  state: State = initialState,
  action: Actions
): State {
  if (action.type === ReduxActionType.SET_LAST_ODDS) {
    return [
      ...state,
      {
        ...action.payload.odds,
        home: decimalToODDS(action.payload.odds.home),
        away: decimalToODDS(action.payload.odds.away),
      },
    ];
  }
  return state;
}
