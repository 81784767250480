import ReduxState from "../../../redux/state";
import { pick } from "lodash";
import { connect } from "react-redux";
import Clock, { ClockProps } from "./Clock";

const connector = (state: ReduxState): ClockProps => ({
  time: state.lastOdds ? state.lastOdds.timestamp * 1000 : null,
  // time: state.startTime,
});

const withStore = connect(connector);

export default withStore(Clock);
