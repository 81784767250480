import { Sponsor, Match } from "../redux/state";
import { BASE_URL } from "../config";

const SPONSOR_COLOR_BACKGROUND = "linear-gradient(167.63deg, #353535 -8.9%, rgba(51, 51, 51, 0) 111.79%)";
const SPONSOR_COLOR_PRIMARY = "linear-gradient(180deg, #9F2B58 0%, #E01938 100%)";
const SPONSOR_COLOR_SECONDARY = "linear-gradient(180deg, #025D9B 0%, #4C477F 100%)";

const defaultValues = {
  "sponsor-color-background": SPONSOR_COLOR_BACKGROUND,
  "sponsor-color-primary": SPONSOR_COLOR_PRIMARY,
  "sponsor-color-secondary": SPONSOR_COLOR_SECONDARY,
};

export function getStorageValue(key: keyof typeof defaultValues) {
  return localStorage.getItem(`onyx-${key}`) || defaultValues[key];
}

export function setSponsorSettings(sponsor: Sponsor) {
  localStorage.setItem("onyx-sponsor-site", `https://${sponsor.website}`);
  localStorage.setItem("onyx-sponsor-logo", BASE_URL + "/" + sponsor.avatar);
  localStorage.setItem("onyx-sponsor-id", sponsor.id.toString());
  localStorage.setItem("onyx-sponsor-color-primary", sponsor.color_primary);
  localStorage.setItem("onyx-sponsor-color-secondary", sponsor.color_secondary);
  localStorage.setItem("onyx-sponsor-color-stroke", sponsor.color_stroke);
  localStorage.setItem(
    "onyx-sponsor-video-intro",
    BASE_URL + "/" + sponsor.video_intro
  );
  localStorage.setItem(
    "onyx-sponsor-color-background",
    sponsor.color_background
  );
}

export function setMatchSettings(match: Match) {
  localStorage.setItem(
    "onyx-sponsor-live-matchid",
    match.match_api_id.toString()
  );
  localStorage.setItem("onyx-sponsor-match-id", match.id.toString());
  localStorage.setItem("onyx-sponsor-live-name", match.name);
}
