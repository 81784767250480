import React from "react";
import "./index.css";

const Label = ({ children, style, className }) => {
  return (
    <div className={`onyx-label ${className ? className : ""}`} style={style}>
      {children}
    </div>
  );
};

export default Label;
