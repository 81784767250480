import React from "react";
import { getOddDirection } from "../../../helpers/odds.helpers";
import "./index.css";
import { getStorageValue } from "../../../helpers/storage.helpers";

export interface SmallChartsOddProps {
  name: string;
  odds: number;
  oddsAll: any;
  type: string;
}

export default function SmallChartsOdd(props: SmallChartsOddProps) {
  const directionSymbol = getOddDirection(props.odds);

  const PrimaryColor = getStorageValue("sponsor-color-primary");
  const SecondaryColor = getStorageValue("sponsor-color-secondary");

  const homePlayerColor = PrimaryColor.length < 20 ? `${PrimaryColor}`: '#D21E43';
  const awayPlayerColor = SecondaryColor.length < 20 ? `${SecondaryColor}` : '#075B9A';

  let arrow;

  if(props.type === 'home') {
    if (props.oddsAll[props.oddsAll.length - 1]?.home > props.oddsAll[props.oddsAll.length - 2]?.home) {
      arrow = <svg width="40" height="48" viewBox="0 0 26 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.2725 31C11.2725 31.9541 12.0459 32.7275 13 32.7275C13.9541 32.7275 14.7275 31.9541 14.7275 31L11.2725 31ZM14.2215 0.778496C13.5469 0.103876 12.4531 0.103876 11.7785 0.778496L0.784956 11.772C0.110337 12.4467 0.110337 13.5404 0.784955 14.215C1.45957 14.8897 2.55335 14.8897 3.22796 14.215L13 4.44301L22.772 14.215C23.4467 14.8897 24.5404 14.8897 25.215 14.215C25.8897 13.5404 25.8897 12.4467 25.215 11.772L14.2215 0.778496ZM14.7275 31L14.7275 2L11.2725 2L11.2725 31L14.7275 31Z" fill={homePlayerColor} />
      </svg>;
    } else {
      arrow = <svg width="40" height="48" viewBox="0 0 24 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.5 2C13.5 1.17157 12.8284 0.5 12 0.5C11.1716 0.5 10.5 1.17157 10.5 2L13.5 2ZM10.9393 32.0607C11.5251 32.6464 12.4749 32.6464 13.0607 32.0607L22.6066 22.5147C23.1924 21.9289 23.1924 20.9792 22.6066 20.3934C22.0208 19.8076 21.0711 19.8076 20.4853 20.3934L12 28.8787L3.51471 20.3934C2.92893 19.8076 1.97918 19.8076 1.39339 20.3934C0.807608 20.9792 0.807608 21.9289 1.39339 22.5147L10.9393 32.0607ZM10.5 2L10.5 31L13.5 31L13.5 2L10.5 2Z" fill={homePlayerColor} />
      </svg>;
    }
  } else {
    if (props.oddsAll[props.oddsAll.length - 1]?.away > props.oddsAll[props.oddsAll.length - 2]?.away) {
      arrow = <svg width="40" height="48" viewBox="0 0 26 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.2725 31C11.2725 31.9541 12.0459 32.7275 13 32.7275C13.9541 32.7275 14.7275 31.9541 14.7275 31L11.2725 31ZM14.2215 0.778496C13.5469 0.103876 12.4531 0.103876 11.7785 0.778496L0.784956 11.772C0.110337 12.4467 0.110337 13.5404 0.784955 14.215C1.45957 14.8897 2.55335 14.8897 3.22796 14.215L13 4.44301L22.772 14.215C23.4467 14.8897 24.5404 14.8897 25.215 14.215C25.8897 13.5404 25.8897 12.4467 25.215 11.772L14.2215 0.778496ZM14.7275 31L14.7275 2L11.2725 2L11.2725 31L14.7275 31Z" fill={awayPlayerColor} />
      </svg>;
    } else {
      arrow = <svg width="40" height="48" viewBox="0 0 24 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.5 2C13.5 1.17157 12.8284 0.5 12 0.5C11.1716 0.5 10.5 1.17157 10.5 2L13.5 2ZM10.9393 32.0607C11.5251 32.6464 12.4749 32.6464 13.0607 32.0607L22.6066 22.5147C23.1924 21.9289 23.1924 20.9792 22.6066 20.3934C22.0208 19.8076 21.0711 19.8076 20.4853 20.3934L12 28.8787L3.51471 20.3934C2.92893 19.8076 1.97918 19.8076 1.39339 20.3934C0.807608 20.9792 0.807608 21.9289 1.39339 22.5147L10.9393 32.0607ZM10.5 2L10.5 31L13.5 31L13.5 2L10.5 2Z" fill={awayPlayerColor} />
      </svg>;
    }
  }
  
  return (
    <div className="player-wrapper">
      <span className="player-score" style={props.odds === 0 ? {margin: 'auto'} : {}}>
        
        {directionSymbol}
        {props.odds && Number.isInteger(props.odds) ? props.odds && props.odds : props.odds && (props.odds * 1.000000000000001).toFixed(2)}
        <div style={{marginLeft: '4px', display: "flex", alignItems: 'center'}}>
        {arrow}
        </div>
        <span className="player-name">{props.name}</span>
      </span>
    </div>
  );
}
