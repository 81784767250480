import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

import "./index.css";
import SponsorLogo from "../SponsorLogo";
import PlayerStatsCharts from "../../data-visualisation/PlayerStatsCharts";
import PlayerStatsNames from "../PlayerStatsNames";
import PlayerStatsPictures from "../PlayerStatsPictures";
import ReduxState from "../../../redux/state";
import { pick } from "lodash";
import { connect } from "react-redux";
import { getStorageValue } from "../../../helpers/storage.helpers";

const oddsConverter = require('odds-converter')

export interface PlayerStatsProps {
  setStep: (step: number) => void;
}

interface SmallChartsOwnProps {
  names: any;
  toggleConvertOdds: boolean;
  setStatToggle: (statToggle: boolean) => boolean;
  setInfoToggle: (infoToggle: boolean) => boolean;
  match: any;
  odds: any;
  step: number;
}
type SmallChartsStore = Pick<ReduxState, "lastOdds">;

type SmallChartsProps = SmallChartsOwnProps & SmallChartsStore & PlayerStatsProps;

const connector = (state: ReduxState): SmallChartsStore =>
  pick(state, ["lastOdds"], ["match"], ["odds"]);

const withStore = connect(connector);

export default withStore(function PlayersStats(props: SmallChartsProps) {
  const { lastOdds, names, toggleConvertOdds, setInfoToggle, match, odds, step, setStatToggle } = props;

  const PrimaryColor = getStorageValue("sponsor-color-primary");
  const SecondaryColor = getStorageValue("sponsor-color-secondary");
  const homePlayerColor = PrimaryColor.length < 20 ? `${PrimaryColor}`: '#D21E43';
  const awayPlayerColor = SecondaryColor.length < 20 ? `${SecondaryColor}` : '#075B9A';

  let oddsHome = lastOdds && toggleConvertOdds ? oddsConverter.american.toDecimal(lastOdds.home) : lastOdds ? lastOdds.home : 0;
  let oddsAway = lastOdds && toggleConvertOdds ? oddsConverter.american.toDecimal(lastOdds.away) : lastOdds ? lastOdds.away : 0;

  const [animationWidth, setAnimationWidth] = useState([0, 500]);
  const [animationOpacity, setAnimationOpacity] = useState([0, 1]);
  const [animationOpacityHere, setAnimationOpacityHere] = useState();
  const [animationTransitionTop, setAnimationTransitionTop] = useState({ delay: 1 });
  const [animationTransitionBottom, setAnimationTransitionBottom] = useState({ delay: 1.5 });
  const [defDelay, setDefDelay] = useState(4);
  const [defDuration, setDefDuration] = useState(2);
  const [defOpacity, setDefOpacity] = useState(1);
  const [defScaleHidden, setDefScaleHidden] = useState(0);
  const [defScaleVisable, setDefScaleVisable] = useState(1);

  const handleCloseStats = () => {
    setAnimationWidth([500, 0])
    setAnimationOpacity([1, 0])
    setAnimationTransitionTop({ delay: 0 })
    setAnimationTransitionBottom({ delay: 0 })

    setDefDelay(0)
    setDefDuration(0)
    setDefOpacity(0)
    setDefScaleHidden(1)
    setDefScaleVisable(0)
    setTimeout(() => {
      handleClose()
    }, 1000);
  }

  const handleClose = () => {
    props.setStep(1)
  }

  let arrowHome;
  if (odds[odds.length - 1]?.home > odds[odds.length - 2]?.home) {
    arrowHome = <svg width="26" height="33" viewBox="0 0 26 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.2725 31C11.2725 31.9541 12.0459 32.7275 13 32.7275C13.9541 32.7275 14.7275 31.9541 14.7275 31L11.2725 31ZM14.2215 0.778496C13.5469 0.103876 12.4531 0.103876 11.7785 0.778496L0.784956 11.772C0.110337 12.4467 0.110337 13.5404 0.784955 14.215C1.45957 14.8897 2.55335 14.8897 3.22796 14.215L13 4.44301L22.772 14.215C23.4467 14.8897 24.5404 14.8897 25.215 14.215C25.8897 13.5404 25.8897 12.4467 25.215 11.772L14.2215 0.778496ZM14.7275 31L14.7275 2L11.2725 2L11.2725 31L14.7275 31Z" fill={homePlayerColor} />
    </svg>;
  } else {
    arrowHome = <svg width="24" height="33" viewBox="0 0 24 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.5 2C13.5 1.17157 12.8284 0.5 12 0.5C11.1716 0.5 10.5 1.17157 10.5 2L13.5 2ZM10.9393 32.0607C11.5251 32.6464 12.4749 32.6464 13.0607 32.0607L22.6066 22.5147C23.1924 21.9289 23.1924 20.9792 22.6066 20.3934C22.0208 19.8076 21.0711 19.8076 20.4853 20.3934L12 28.8787L3.51471 20.3934C2.92893 19.8076 1.97918 19.8076 1.39339 20.3934C0.807608 20.9792 0.807608 21.9289 1.39339 22.5147L10.9393 32.0607ZM10.5 2L10.5 31L13.5 31L13.5 2L10.5 2Z" fill={homePlayerColor} />
    </svg>;
  }

  let arrowAway;
  if (odds[odds.length - 1]?.away > odds[odds.length - 2]?.away) {
    arrowAway = <svg width="26" height="33" viewBox="0 0 26 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.2725 31C11.2725 31.9541 12.0459 32.7275 13 32.7275C13.9541 32.7275 14.7275 31.9541 14.7275 31L11.2725 31ZM14.2215 0.778496C13.5469 0.103876 12.4531 0.103876 11.7785 0.778496L0.784956 11.772C0.110337 12.4467 0.110337 13.5404 0.784955 14.215C1.45957 14.8897 2.55335 14.8897 3.22796 14.215L13 4.44301L22.772 14.215C23.4467 14.8897 24.5404 14.8897 25.215 14.215C25.8897 13.5404 25.8897 12.4467 25.215 11.772L14.2215 0.778496ZM14.7275 31L14.7275 2L11.2725 2L11.2725 31L14.7275 31Z" fill={awayPlayerColor} />
    </svg>;
  } else {
    arrowAway = <svg width="24" height="33" viewBox="0 0 24 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.5 2C13.5 1.17157 12.8284 0.5 12 0.5C11.1716 0.5 10.5 1.17157 10.5 2L13.5 2ZM10.9393 32.0607C11.5251 32.6464 12.4749 32.6464 13.0607 32.0607L22.6066 22.5147C23.1924 21.9289 23.1924 20.9792 22.6066 20.3934C22.0208 19.8076 21.0711 19.8076 20.4853 20.3934L12 28.8787L3.51471 20.3934C2.92893 19.8076 1.97918 19.8076 1.39339 20.3934C0.807608 20.9792 0.807608 21.9289 1.39339 22.5147L10.9393 32.0607ZM10.5 2L10.5 31L13.5 31L13.5 2L10.5 2Z" fill={awayPlayerColor} />
    </svg>;
  }

  const container = {
    hidden: { opacity: defOpacity, scale: defScaleHidden },
    visible: {
      opacity: defOpacity,
      scale: defScaleVisable,
      transition: {
        delay: defDelay,
        duration: defDuration,
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    }
  };


  return (
    <div className="onyx-player-stats">
      <PlayerStatsCharts step={step} animationWidth={animationWidth} animationOpacity={animationOpacity} animationTransitionTop={animationTransitionTop} animationTransitionBottom={animationTransitionBottom} />
      <motion.div
        className="onyx-player-stats-player-picture"
        variants={container}
        initial="hidden"
        // animate={{opacity: animationOpacity ? [1, 0] : [0, 1]}
        animate={defScaleHidden ? "hidden" : "visible"}
        style={{ justifyContent: "flex-end", right: '15px' }}
      // animate={{ opacity: !infoToggle ? [1, 0] : [0, 1]}}

      >
        <PlayerStatsPictures player="home" homePlayerColor={homePlayerColor} awayPlayerColor={awayPlayerColor}/>
        <div className="onyx-player-stats-player-odds">
          <div className="onyx-player-stats-player-odds-text">{lastOdds && toggleConvertOdds ? oddsConverter.american.toDecimal(lastOdds.away) : lastOdds ? lastOdds.away : 0}</div>
          <div>{arrowAway}</div>
        </div>
      </motion.div>
      <motion.div
        className="onyx-player-stats-player-picture"
        variants={container}
        initial="hidden"
        // animate="visible"
        animate={defScaleHidden ? "hidden" : "visible"}
        style={{ justifyContent: "flex-start", left: '15px' }}
      >
        <div className="onyx-player-stats-player-odds">
          <div className="onyx-player-stats-player-odds-text">{lastOdds && toggleConvertOdds ? oddsConverter.american.toDecimal(lastOdds.home) : lastOdds ? lastOdds.home : 0}</div>

          <div>{arrowHome}</div>
        </div>
        <PlayerStatsPictures player="away" />
      </motion.div>
      <PlayerStatsNames handleCloseStats={handleCloseStats} defScaleHidden={defScaleHidden}/>
      <div className="onyx-players-stats-charts-bar-close" onClick={handleCloseStats}>
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line y1="-1.5" x2="25.8291" y2="-1.5" transform="matrix(0.71718 0.696888 -0.71718 0.696888 0 3)" stroke="white" stroke-width="3" />
          <line y1="-1.5" x2="25.8291" y2="-1.5" transform="matrix(0.71718 0.696888 -0.71718 0.696888 0 3)" stroke="white" stroke-width="3" />
          <line y1="-1.5" x2="25.8291" y2="-1.5" transform="matrix(-0.71718 0.696888 0.71718 0.696888 21 3)" stroke="white" stroke-width="3" />
          <line y1="-1.5" x2="25.8291" y2="-1.5" transform="matrix(-0.71718 0.696888 0.71718 0.696888 21 3)" stroke="white" stroke-width="3" />
        </svg>
      </div>
    </div>
  );
})
