import React, { useState, useEffect } from "react";
import ReduxState from "../../../redux/state";
import { pick } from "lodash";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import { getStorageValue } from "../../../helpers/storage.helpers";
import { trackRequest } from '../../../api';
import "./index.css";
import Draggable from 'react-draggable';

function format_time(timestamp: number) {
  let date = new Date(timestamp * 1000);
  return ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
}

interface SmallChartsOwnProps {
  names: any;
  toggleConvertOdds: boolean;
  setInfoToggle: (infoToggle: boolean) => boolean;
  match: any;
  time: number;
  odds: any;
  infoToggle: boolean;
  statToggle: boolean;
  step: number;
  setStatToggle: (statToggle: boolean) => boolean;
  setStep: (step: number) => number;
}

type SmallChartsStore = Pick<ReduxState, "lastOdds">;

type SmallChartsProps = SmallChartsOwnProps & SmallChartsStore;
const connector = (state: ReduxState): SmallChartsStore =>
  pick(state, ["lastOdds"], ["match"], ["odds"]);

const withStore = connect(connector);

export default withStore(function SmallCharts(props: SmallChartsProps) {
  const { odds, setStatToggle, setInfoToggle, setStep, step, infoToggle, statToggle } = props;
  const [defaultColor1, setDefaultColor1] = useState('#353535');
  const [defaultColor2, setDefaultColor2] = useState('#353535');
  const [defaultColor3, setDefaultColor3] = useState('#353535');
  const [allInfoButtons, setAllInfoButtons] = useState(false);
  const [visableWidget, setVisableWidget] = useState("hidden");
  const [valueTime, setValueTime] = useState(new Date());

  const backgroundColor = getStorageValue("sponsor-color-background");
  // const backgroundGradient = backgroundColor && backgroundColor !== 'null' ? `${backgroundColor}` : "#353535";
  const backgroundGradient = `${backgroundColor}`;

  const handleChangeVisable = () => {
    allInfoButtons ? setVisableWidget('visible') : setTimeout(() => setVisableWidget('hidden'), 2000);
  }

  useEffect(() => {
    const interval = setInterval(
      () => setValueTime(new Date()),
      1000
    );
 
    return () => {
      clearInterval(interval);
    }
  }, []);

  const parseTime = (time: any) => {
    return time.getMinutes() > 9 ? `${time.getHours()}:${time.getMinutes()}` : `${time.getHours()}:0${time.getMinutes()}`
  }

  useEffect(() => {
    handleChangeVisable()
  }, [allInfoButtons]);

  const innerStyleButtons = {
    visibility: visableWidget,
  };

  const innerStyle = {
    background: backgroundGradient,
    // filter: backgroundFilter,
    cursor: 'pointer'
  };

  useEffect(() => {
    if (statToggle) {
      setDefaultColor1('#C52446')
    } else {
      backgroundColor && backgroundColor !== 'null' && backgroundColor.length < 20 ? setDefaultColor1(`${backgroundGradient}`) : setDefaultColor1('#353535')
    }
  }, [statToggle]);

  useEffect(() => {
    if (step === 0) {
      setDefaultColor2('#C52446')
    } else {
      backgroundColor && backgroundColor !== 'null' && backgroundColor.length < 20 ? setDefaultColor2(`${backgroundGradient}`) : setDefaultColor2('#353535')
    }
  }, [step]);

  useEffect(() => {
    if (infoToggle) {
      setDefaultColor3('#C52446')
    } else {
      backgroundColor && backgroundColor !== 'null' && backgroundColor.length < 20 ? setDefaultColor3(`${backgroundGradient}`) : setDefaultColor3('#353535')
    }
  }, [infoToggle]);

  // const handleShowButtons = () => {
  //   setAllInfoButtons(!allInfoButtons)
  // }

  const handle1button = () => {
    setStatToggle(!statToggle)
    setStep(1)
  }

  const handle2button = () => {
    setStatToggle(false);
    setStep(0);
  }

  const handle3button = () => {
    setInfoToggle(!infoToggle)
  }

  const lastTimeStamp = odds && odds[odds.length - 1]?.timestamp

  const resultData = lastTimeStamp ? format_time(lastTimeStamp) : ''
  // const resultData = lastTimeStamp ? format_time(lastTimeStamp) : ''
  return (
    // <motion.div drag className="widgetTime-wrapper_all" onMouseEnter={() => setAllInfoButtons(true)} onMouseOver={() => setAllInfoButtons(true)} onMouseLeave={() => setAllInfoButtons(false)} onMouseOut={() => setAllInfoButtons(false)} >
    <Draggable>
      <motion.div className="widgetTime-wrapper_all"
        onMouseOver={() => setAllInfoButtons(true)} onMouseLeave={() => setAllInfoButtons(false)}>
        <motion.div
          animate={{ opacity: allInfoButtons ? [0, 1] : [1, 0] }}
          transition={{ duration: 1.5 }}
          className='widget-and-buttons-wrapper'
          // style={localStorage.getItem("onyx-sponsor-logo") && 
          //   localStorage.getItem("onyx-sponsor-logo") !== 'http://localhost:8000/' ? 
          //   { bottom: '21px' } : 
          //   { bottom: '5px' }}
          // style={allInfoButtons ? {} : {position: 'relative', top: '-7px'}}
          style={allInfoButtons ? { visibility: 'visible' } : { visibility: 'hidden' }}

        // style={innerStyle}
        >
          <div className='widget-and-buttons-wrapper-one-button widg-button-1' onClick={handle1button}>
            <svg width="103" height="107" viewBox="0 0 89 102" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_b)">
                <path d="M79.9814 18.4348C79.5582 47.4695 71.5716 75.8921 56.8098 100.898L7.17766 71.598C16.8449 55.2223 22.0751 36.609 22.3523 17.5947L79.9814 18.4348Z" fill={defaultColor1} fill-opacity="1" />
              </g>
              <g filter="url(#filter1_d)">
                <path d="M46.9636 59H49.5818C50.0182 59 50.4545 58.55 50.4545 58.1V48.65C50.4545 48.2 50.0182 47.75 49.5818 47.75H46.9636C46.5273 47.75 46.0909 48.2 46.0909 48.65V58.1C46.0909 58.55 46.5273 59 46.9636 59ZM53.5091 59H56.1273C56.5636 59 57 58.55 57 58.1V41.9C57 41.45 56.5636 41 56.1273 41H53.5091C53.0727 41 52.6364 41.45 52.6364 41.9V58.1C52.6364 58.55 53.0727 59 53.5091 59ZM33.8727 59H36.4909C36.9273 59 37.3636 58.55 37.3636 58.1V53.15C37.3636 52.7 36.9273 52.25 36.4909 52.25H33.8727C33.4364 52.25 33 52.7 33 53.15V58.1C33 58.55 33.4364 59 33.8727 59ZM40.4182 59H43.0364C43.4727 59 43.9091 58.55 43.9091 58.1V44.15C43.9091 43.7 43.4727 43.25 43.0364 43.25H40.4182C39.9818 43.25 39.5455 43.7 39.5455 44.15V58.1C39.5455 58.55 39.9818 59 40.4182 59Z" fill="url(#paint0_linear)" />
              </g>
              <defs>
                <filter id="filter0_b" x="-29.8115" y="-29.5732" width="147.844" height="161.543" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feGaussianBlur in="BackgroundImage" stdDeviation="15" />
                  <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape" />
                </filter>
                <filter id="filter1_d" x="15.3253" y="23.3253" width="59.3493" height="53.3493" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                  <feOffset />
                  <feGaussianBlur stdDeviation="8.83733" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
                <linearGradient id="paint0_linear" x1="45" y1="41" x2="45" y2="59" gradientUnits="userSpaceOnUse">
                  <stop stop-color="white" />
                  <stop offset="1" stop-color="white" stop-opacity="0.81" />
                </linearGradient>
              </defs>
            </svg>

          </div>
          <div className='widget-and-buttons-wrapper-one-button widg-button-2' onClick={handle2button}>
            <svg width="112" height="115" viewBox="0 0 107 95" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
                <g filter="url(#filter0_b)">
                  <path d="M101.507 31.5349C86.7516 56.5443 65.7301 77.2741 40.5169 91.6786L11.9264 41.6346C28.4379 32.2014 42.2045 18.6259 51.8675 2.2478L101.507 31.5349Z" fill={defaultColor2} fill-opacity="1" />
                </g>
                <g filter="url(#filter1_d)">
                  <path d="M51.8748 44.1738C54.1961 44.1738 56.0748 42.2727 56.0748 39.9238C56.0748 37.5749 54.1961 35.6738 51.8748 35.6738C49.5536 35.6738 47.6748 37.5749 47.6748 39.9238C47.6748 42.2727 49.5536 44.1738 51.8748 44.1738ZM54.7548 45.3881H54.4436C53.6636 45.7676 52.7973 45.9953 51.8748 45.9953C50.9523 45.9953 50.0898 45.7676 49.3061 45.3881H48.9948C46.6098 45.3881 44.6748 47.3462 44.6748 49.7595V50.8524C44.6748 51.858 45.4811 52.6738 46.4748 52.6738H57.2748C58.2686 52.6738 59.0748 51.858 59.0748 50.8524V49.7595C59.0748 47.3462 57.1398 45.3881 54.7548 45.3881ZM62.6748 44.1738C64.6623 44.1738 66.2748 42.5421 66.2748 40.531C66.2748 38.5198 64.6623 36.8881 62.6748 36.8881C60.6873 36.8881 59.0748 38.5198 59.0748 40.531C59.0748 42.5421 60.6873 44.1738 62.6748 44.1738ZM64.4748 45.3881H64.3323C63.8111 45.5703 63.2598 45.6917 62.6748 45.6917C62.0898 45.6917 61.5386 45.5703 61.0173 45.3881H60.8748C60.1098 45.3881 59.4048 45.612 58.7861 45.9725C59.7011 46.9705 60.2748 48.2948 60.2748 49.7595V51.2167C60.2748 51.3002 60.2561 51.3799 60.2523 51.4595H66.8748C67.8686 51.4595 68.6748 50.6437 68.6748 49.6381C68.6748 47.2892 66.7961 45.3881 64.4748 45.3881V45.3881Z" fill="url(#paint1_linear)" />
                </g>
              </g>
              <defs>
                <filter id="filter0_b" x="-30.5332" y="-35.9004" width="165.764" height="170.02" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feGaussianBlur in="BackgroundImage" stdDeviation="15" />
                  <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape" />
                </filter>
                <filter id="filter1_d" x="27.0001" y="17.9992" width="59.3493" height="52.3493" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                  <feOffset />
                  <feGaussianBlur stdDeviation="8.83733" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
                <linearGradient id="paint0_linear" x1="-10.7695" y1="-1469.96" x2="-214.744" y2="382.042" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#353535" />
                  <stop offset="1" stop-color="#333333" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint1_linear" x1="56.6748" y1="35.6738" x2="56.6748" y2="52.6738" gradientUnits="userSpaceOnUse">
                  <stop stop-color="white" />
                  <stop offset="1" stop-color="white" stop-opacity="0.81" />
                </linearGradient>
                <clipPath id="clip0">
                  <rect width="107" height="95" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className='widget-and-buttons-wrapper-one-button widg-button-3' onClick={handle3button}>
            <svg width="117" height="115" viewBox="0 0 113 107" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_b)">
                <path d="M99.8714 65.9878C79.3043 77.7434 56.5266 84.9583 33.0669 87.2258C24.2713 88.0759 17.0876 80.8369 17.0828 72.0004L17.06 30.3651C36.0762 30.3547 54.7611 25.3861 71.2707 15.9496L99.8714 65.9878Z" fill={defaultColor3} fill-opacity="1" />
              </g>
              <g clip-path="url(#clip0)" filter="url(#filter1_d)">
                <g filter="url(#filter2_d)">
                  <path d="M47.9375 60.2286H48.875V54.0214H47.9375C47.4197 54.0214 47 53.6367 47 53.162V51.1094C47 50.6347 47.4197 50.25 47.9375 50.25H53.1875C53.7053 50.25 54.125 50.6347 54.125 51.1094V60.2286H55.0625C55.5803 60.2286 56 60.6133 56 61.088V63.1406C56 63.6153 55.5803 64 55.0625 64H47.9375C47.4197 64 47 63.6153 47 63.1406V61.088C47 60.6133 47.4197 60.2286 47.9375 60.2286ZM51.5 42C49.636 42 48.125 43.3851 48.125 45.0938C48.125 46.8024 49.636 48.1875 51.5 48.1875C53.364 48.1875 54.875 46.8024 54.875 45.0938C54.875 43.3851 53.3639 42 51.5 42Z" fill="url(#paint1_linear)" />
                </g>
              </g>
              <defs>
                <filter id="filter0_b" x="-29.3145" y="-29.3418" width="171.642" height="166.273" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feGaussianBlur in="BackgroundImage" stdDeviation="15" />
                  <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape" />
                </filter>
                <filter id="filter1_d" x="29.3253" y="24.3253" width="44.3493" height="57.3493" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                  <feOffset />
                  <feGaussianBlur stdDeviation="8.83733" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
                <filter id="filter2_d" x="29.3253" y="24.3253" width="44.3493" height="57.3493" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                  <feOffset />
                  <feGaussianBlur stdDeviation="8.83733" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
                <linearGradient id="paint0_linear" x1="58.8489" y1="-309.96" x2="1.5914" y2="166.709" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#353535" />
                  <stop offset="1" stop-color="#333333" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint1_linear" x1="51.5" y1="42" x2="51.5" y2="64" gradientUnits="userSpaceOnUse">
                  <stop stop-color="white" />
                  <stop offset="1" stop-color="white" stop-opacity="0.81" />
                </linearGradient>
                <clipPath id="clip0">
                  <rect width="9" height="22" fill="white" transform="translate(47 42)" />
                </clipPath>
              </defs>
            </svg>

          </div>
        </motion.div>
        <motion.div className="widgetTime-wrapper" style={innerStyle}>

          <div className="widgetTime-wrapper-top"></div>
          {/* {allInfoButtons ? */}
          <div className="logo-wrapper">
            <a
              href={localStorage.getItem("onyx-sponsor-site") as string}
              target="_blank"
            >
              {localStorage.getItem("onyx-sponsor-logo") && localStorage.getItem("onyx-sponsor-logo") !== 'http://localhost:8000/' ?
                <div className="logo-wrapper_img-wrapper">
                  <img
                    alt="logo"
                    src={localStorage.getItem("onyx-sponsor-logo") || "bet365.png"}
                  />
                </div>
                :
                <div className="logo-wrapper_img-wrapper">
                  {/* <img
                  alt="logo"
                  src='https://ua.coca-colahellenic.com/content/dam/cch/ua/Coca-Cola%20HBC%20LOGO-BU%20UAM.png'
                /> */}
                </div>
              }
            </a>
          </div>
          {/* :
          <div className="logo-wrapper-close">
            <a
              href={localStorage.getItem("onyx-sponsor-site") as string}
              target="_blank"
            >
              {localStorage.getItem("onyx-sponsor-logo") && localStorage.getItem("onyx-sponsor-logo") !== 'http://localhost:8000/' ?
                <div className="logo-wrapper_img-wrapper">
                  <img
                    alt="logo"
                    src={localStorage.getItem("onyx-sponsor-logo") || "bet365.png"}
                  />
                </div>
                :
                <div className="logo-wrapper_img-wrapper">
                  
                </div>
              }
            </a>
          </div>} */}
          {/* {allInfoButtons ?  */}
          <div className="widgetTime-time">{parseTime(valueTime)}</div>
          {/* // : <div className="widgetTime-time-close">{resultData}</div>} */}
        </motion.div>
      </motion.div>
    </Draggable>
  );
});
