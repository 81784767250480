import React, { useState, useEffect } from 'react';
import { useSpring } from 'react-spring';
import { motion } from 'framer-motion';
import Switch from "react-switch";
import ReduxState from "../../../redux/state";

import SponsorLogo from '../SponsorLogo';
import { trackRequest } from '../../../api';
import Clock from '../Clock/container';
import { getStorageValue } from '../../../helpers/storage.helpers';

import './index.css';
import LivePlayers from '../../LivePlayers';
import LiveOddsChartsContainer from '../LiveOddsChartsContainer';
import { pick } from "lodash";
import { connect } from "react-redux";
const oddsConverter = require('odds-converter')

interface LiveOddsTrackerOwnProps {
  hovered: boolean;
  step: number;
  setStep: (step: number) => void;
  setIsBigWidget: (b: boolean) => void;
  isBigWidget: boolean;
  toggleConvertOdds: boolean;
  setToggleConvertOdds: (toggleConvertOdds: boolean) => boolean;
  toggleZoomOdds: boolean;
  statToggle: boolean;
  setStatToggle: (statToggle: boolean) => boolean;
  setToggleZoomOdds: (toggleZoomOdds: boolean) => boolean;
}
type SmallChartsStore = Pick<ReduxState, "odds">;
const connector = (state: ReduxState): SmallChartsStore =>
  pick(state, ["odds"]);

const withStore = connect(connector);
type LiveOddsTrackerProps = LiveOddsTrackerOwnProps & SmallChartsStore;

// @ts-ignore
let currentTimeOut;

export default withStore(function LiveOddsTracker(props: LiveOddsTrackerProps) {
  const { hovered, toggleZoomOdds, toggleConvertOdds, setToggleConvertOdds, setStatToggle, setToggleZoomOdds, statToggle, odds, step } = props;
  const [visableWidget, setVisableWidget] = useState('none')

  const backgroundColor = getStorageValue('sponsor-color-background');
  const backgroundGradient = backgroundColor && backgroundColor !== 'null' ? `${backgroundColor}` : "linear-gradient(167.63deg, #353535 -8.9%, rgba(51, 51, 51, 0) 111.79%)";
  const backgroundFilter = `drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))`;
  const backgroundFilterBackdrop = `blur(20px)`;
  const innerStyle = {
    background: backgroundGradient,
    filter: backgroundFilter,
    backDrop: backgroundFilterBackdrop
  };

  function handleChangeVisableSetTimeout() {
    currentTimeOut = setTimeout(() => setVisableWidget('none'), 5000);
  }

  function myStopFunction() {
    // @ts-ignore
    clearTimeout(currentTimeOut);
  }

  const handleChangeVisable = () => {
    myStopFunction()
    statToggle ? setVisableWidget('flex') : handleChangeVisableSetTimeout();
  }
  const innerStyleWrapper = {
    display: visableWidget,
  };

  useEffect(() => {
    handleChangeVisable()
  }, [statToggle]);

  const PrimaryColor = getStorageValue("sponsor-color-primary");
  const SecondaryColor = getStorageValue("sponsor-color-secondary");
  const homePlayerColor = PrimaryColor.length < 20 ? `${PrimaryColor}` : '#D21E43';
  const awayPlayerColor = SecondaryColor.length < 20 ? `${SecondaryColor}` : '#075B9A';
  function onChange(checked: any) {
    setToggleConvertOdds(checked)
  }

  // let oddsHome = lastOdds && toggleConvertOdds ? oddsConverter.american.toDecimal(lastOdds.home) : lastOdds ? lastOdds.home : 0;
  // let oddsAway = lastOdds && toggleConvertOdds ? oddsConverter.american.toDecimal(lastOdds.away) : lastOdds ? lastOdds.away : 0;

  let arrowHome;
  if (odds[odds.length - 1]?.home > odds[odds.length - 2]?.home) {
    arrowHome = <svg width="16" height="23" viewBox="0 0 26 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.2725 31C11.2725 31.9541 12.0459 32.7275 13 32.7275C13.9541 32.7275 14.7275 31.9541 14.7275 31L11.2725 31ZM14.2215 0.778496C13.5469 0.103876 12.4531 0.103876 11.7785 0.778496L0.784956 11.772C0.110337 12.4467 0.110337 13.5404 0.784955 14.215C1.45957 14.8897 2.55335 14.8897 3.22796 14.215L13 4.44301L22.772 14.215C23.4467 14.8897 24.5404 14.8897 25.215 14.215C25.8897 13.5404 25.8897 12.4467 25.215 11.772L14.2215 0.778496ZM14.7275 31L14.7275 2L11.2725 2L11.2725 31L14.7275 31Z" fill={homePlayerColor} />
    </svg>;
  } else {
    arrowHome = <svg width="14" height="23" viewBox="0 0 24 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.5 2C13.5 1.17157 12.8284 0.5 12 0.5C11.1716 0.5 10.5 1.17157 10.5 2L13.5 2ZM10.9393 32.0607C11.5251 32.6464 12.4749 32.6464 13.0607 32.0607L22.6066 22.5147C23.1924 21.9289 23.1924 20.9792 22.6066 20.3934C22.0208 19.8076 21.0711 19.8076 20.4853 20.3934L12 28.8787L3.51471 20.3934C2.92893 19.8076 1.97918 19.8076 1.39339 20.3934C0.807608 20.9792 0.807608 21.9289 1.39339 22.5147L10.9393 32.0607ZM10.5 2L10.5 31L13.5 31L13.5 2L10.5 2Z" fill={homePlayerColor} />
    </svg>;
  }

  let arrowAway;
  if (odds[odds.length - 1]?.away > odds[odds.length - 2]?.away) {
    arrowAway = <svg width="16" height="23" viewBox="0 0 26 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.2725 31C11.2725 31.9541 12.0459 32.7275 13 32.7275C13.9541 32.7275 14.7275 31.9541 14.7275 31L11.2725 31ZM14.2215 0.778496C13.5469 0.103876 12.4531 0.103876 11.7785 0.778496L0.784956 11.772C0.110337 12.4467 0.110337 13.5404 0.784955 14.215C1.45957 14.8897 2.55335 14.8897 3.22796 14.215L13 4.44301L22.772 14.215C23.4467 14.8897 24.5404 14.8897 25.215 14.215C25.8897 13.5404 25.8897 12.4467 25.215 11.772L14.2215 0.778496ZM14.7275 31L14.7275 2L11.2725 2L11.2725 31L14.7275 31Z" fill={awayPlayerColor} />
    </svg>;
  } else {
    arrowAway = <svg width="14" height="23" viewBox="0 0 24 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.5 2C13.5 1.17157 12.8284 0.5 12 0.5C11.1716 0.5 10.5 1.17157 10.5 2L13.5 2ZM10.9393 32.0607C11.5251 32.6464 12.4749 32.6464 13.0607 32.0607L22.6066 22.5147C23.1924 21.9289 23.1924 20.9792 22.6066 20.3934C22.0208 19.8076 21.0711 19.8076 20.4853 20.3934L12 28.8787L3.51471 20.3934C2.92893 19.8076 1.97918 19.8076 1.39339 20.3934C0.807608 20.9792 0.807608 21.9289 1.39339 22.5147L10.9393 32.0607ZM10.5 2L10.5 31L13.5 31L13.5 2L10.5 2Z" fill={awayPlayerColor} />
    </svg>;
  }
  function onClose(checked: any) {
    setStatToggle(false)
  }
  console.log('statToggle', statToggle)
  const lastTimeStamp = odds && odds[odds.length - 1]?.timestamp
  const lastBeforeTimeStamp = odds && odds[odds.length - 2]?.timestamp
  // const resultData = lastTimeStamp ? format_time(lastTimeStamp) : ''

  const timestampsDiff = (curr: any, prev: any) => {
    let ms_Min = 60 * 1000;
    let ms_Hour = ms_Min * 60;
    let ms_Day = ms_Hour * 24;
    let ms_Mon = ms_Day * 30;
    let ms_Yr = ms_Day * 365;
    let diff = curr - prev;

    if (diff < ms_Min) {
      return Math.round(diff / 1000) + ' seconds ago';

    } else if (diff < ms_Hour) {
      return Math.round(diff / ms_Min) + ' minutes ago';

    } else if (diff < ms_Day) {
      return Math.round(diff / ms_Hour) + ' hours ago';

    } else if (diff < ms_Mon) {
      return 'Around ' + Math.round(diff / ms_Day) + ' days ago';

    } else if (diff < ms_Yr) {
      return 'Around ' + Math.round(diff / ms_Mon) + ' months ago';
    } else {
      return 'Around ' + Math.round(diff / ms_Yr) + ' years ago';
    }
  }

  return (
    <>
      <motion.div
        drag
        className='onyx-main-popup'
        style={innerStyleWrapper}
        // animate={{ opacity: statToggle ? [0, 1] : [0, 0]}}
        animate={{ opacity: statToggle ? [0, 1] : [1, 0] }}
        transition={{ duration: 4.5 }}
        // onClick={async () => {
        //   console.log('clicked!');
        //   await trackRequest();
        // }}
      >

        <div style={innerStyle} className='onyx-main-popup-inner'>
          <div className='onyx-main-popup-inner-top'>
            <div className='onyx-main-popup-inner-top-right_text-title'>LIVE BETS GRAPH</div>
              <div className='onyx-main-popup-inner-top-right_text'>
                {lastTimeStamp && lastBeforeTimeStamp && (
                  <p>UPDATES {timestampsDiff(lastTimeStamp, lastBeforeTimeStamp)}</p>
                )}
              </div>
            <div className='onyx-main-popup-inner-top-disable-block'></div>
            <div className='onyx-main-popup-inner-top-switch'>
              <Switch
                onChange={onChange}
                checked={toggleConvertOdds}
                handleDiameter={22}
                // offHandleColor='#A42140'
                onHandleColor='#fff'
                className='onyx-live-odds-charts-right-buttons-switch'
              />
              <div className='onyx-main-popup-inner-top-switch_text'>CHANGE<br></br>UNIT</div>
            </div>
            <div className='onyx-live-odds-charts-right-buttons-close' onClick={onClose} >
              <svg width="15" height="15" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line y1="-1.5" x2="25.8291" y2="-1.5" transform="matrix(0.71718 0.696888 -0.71718 0.696888 0 3)" stroke="white" stroke-width="3" />
                <line y1="-1.5" x2="25.8291" y2="-1.5" transform="matrix(0.71718 0.696888 -0.71718 0.696888 0 3)" stroke="white" stroke-width="3" />
                <line y1="-1.5" x2="25.8291" y2="-1.5" transform="matrix(-0.71718 0.696888 0.71718 0.696888 21 3)" stroke="white" stroke-width="3" />
                <line y1="-1.5" x2="25.8291" y2="-1.5" transform="matrix(-0.71718 0.696888 0.71718 0.696888 21 3)" stroke="white" stroke-width="3" />
              </svg>

            </div>
          </div>
          <div className='onyx-main-popup-inner-bottom'>
            <div className='onyx-live-sponsor-logo'>
              <SponsorLogo />
            </div>
            <div className='onyx-live-chart'>
              <LivePlayers arrowHome={arrowHome} arrowAway={arrowAway} />
            </div>
            <LiveOddsChartsContainer
              toggleConvertOdds={toggleConvertOdds}
              toggleZoomOdds={toggleZoomOdds}
            />
            <div className='onyx-live-odds-charts-right-buttons'>
              <div className='onyx-live-odds-charts-right-buttons-plus' onClick={() => setToggleZoomOdds(true)}>
                <svg width="27" height="30" viewBox="0 0 27 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="27" height="30">
                    <rect width="27" height="30" fill="#C4C4C4" />
                  </mask>
                  <g mask="url(#mask0)">
                    <circle cx="13" cy="12" r="8" stroke="white" stroke-width="2" />
                    <line x1="17.7431" y1="18.3309" x2="23.7653" y2="25.0192" stroke="white" stroke-width="2" />
                    <line y1="-1" x2="10" y2="-1" transform="matrix(-0.999848 -0.0174524 0.0103768 -0.999946 18 11)" stroke="white" stroke-width="2" />
                    <line y1="-1" x2="10" y2="-1" transform="matrix(-0.999848 -0.0174524 0.0103768 -0.999946 18 11)" stroke="white" stroke-width="2" />
                    <line y1="-1" x2="10" y2="-1" transform="matrix(0.0174524 -0.999848 -0.999699 -0.0245252 12 16.9993)" stroke="white" stroke-width="2" />
                    <line y1="-1" x2="10" y2="-1" transform="matrix(0.0174524 -0.999848 -0.999699 -0.0245252 12 16.9993)" stroke="white" stroke-width="2" />
                  </g>
                </svg>

              </div>
              <div className='onyx-live-odds-charts-right-buttons-min' onClick={() => setToggleZoomOdds(false)}>
                <svg width="27" height="30" viewBox="0 0 27 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="27" height="30">
                    <rect width="27" height="30" fill="#C4C4C4" />
                  </mask>
                  <g mask="url(#mask0)">
                    <circle cx="13" cy="12" r="8" stroke="white" stroke-width="2" />
                    <line x1="17.7431" y1="18.3309" x2="23.7653" y2="25.0192" stroke="white" stroke-width="2" />
                    <line y1="-1" x2="10" y2="-1" transform="matrix(-0.999848 -0.0174524 0.0103768 -0.999946 18 11)" stroke="white" stroke-width="2" />
                    <line y1="-1" x2="10" y2="-1" transform="matrix(-0.999848 -0.0174524 0.0103768 -0.999946 18 11)" stroke="white" stroke-width="2" />
                  </g>
                </svg>

              </div>
            </div>
          </div>
        </div>

        <div className='onyx-live-timer'>
          <Clock />
          <span className='onyx-live-timer-title' style={innerStyle}>
            LIVE ODDS TRACKER
        </span>
        </div>
      </motion.div>
      {/* <div className='App_info-stat_buttons'>
        <div className='App_Left-side_stat-button' >
          <svg width="34" height="28" viewBox="0 0 34 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d)">
              <path d="M18.7668 21.6457H21.071C21.4551 21.6457 21.8391 21.2604 21.8391 20.8751V12.7831C21.8391 12.3977 21.4551 12.0124 21.071 12.0124H18.7668C18.3828 12.0124 17.9987 12.3977 17.9987 12.7831V20.8751C17.9987 21.2604 18.3828 21.6457 18.7668 21.6457ZM24.5273 21.6457H26.8316C27.2156 21.6457 27.5996 21.2604 27.5996 20.8751V7.00306C27.5996 6.61772 27.2156 6.23239 26.8316 6.23239H24.5273C24.1433 6.23239 23.7593 6.61772 23.7593 7.00306V20.8751C23.7593 21.2604 24.1433 21.6457 24.5273 21.6457ZM7.24573 21.6457H9.54995C9.93398 21.6457 10.318 21.2604 10.318 20.8751V16.6364C10.318 16.2511 9.93398 15.8657 9.54995 15.8657H7.24573C6.8617 15.8657 6.47766 16.2511 6.47766 16.6364V20.8751C6.47766 21.2604 6.8617 21.6457 7.24573 21.6457ZM13.0063 21.6457H15.3105C15.6945 21.6457 16.0786 21.2604 16.0786 20.8751V8.92973C16.0786 8.54439 15.6945 8.15906 15.3105 8.15906H13.0063C12.6222 8.15906 12.2382 8.54439 12.2382 8.92973V20.8751C12.2382 21.2604 12.6222 21.6457 13.0063 21.6457Z" fill="white" />
            </g>
            <defs>
              <filter id="filter0_d" x="0.769019" y="0.523749" width="32.5393" height="26.8306" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                <feOffset />
                <feGaussianBlur stdDeviation="2.85432" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
              </filter>
            </defs>
          </svg>
        </div>
        <div className='App_Left-side_info-button'>
          <svg width="9" height="22" viewBox="0 0 9 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.16045 17.9059H1.99296V12.1075H1.16045C0.700657 12.1075 0.327942 11.7481 0.327942 11.3048V9.38728C0.327942 8.94391 0.700657 8.58451 1.16045 8.58451H5.82251C6.28231 8.58451 6.65502 8.94391 6.65502 9.38728V17.9059H7.48753C7.94733 17.9059 8.32004 18.2653 8.32004 18.7087V20.6262C8.32004 21.0695 7.94733 21.4289 7.48753 21.4289H1.16045C0.700657 21.4289 0.327942 21.0695 0.327942 20.6262V18.7087C0.327942 18.2653 0.700657 17.9059 1.16045 17.9059ZM4.32399 0.877838C2.66875 0.877838 1.32695 2.17172 1.32695 3.76784C1.32695 5.36396 2.66875 6.65784 4.32399 6.65784C5.97923 6.65784 7.32103 5.36396 7.32103 3.76784C7.32103 2.17172 5.97919 0.877838 4.32399 0.877838Z" fill="white" />
          </svg>
        </div>
      </div> */}
    </>
  );
})
