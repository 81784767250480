import React from "react";
import "./index.css";

const InputText = ({
  style,
  className,
  value,
  name,
  onChange,
  type,
  tabIndex,
  placeholder,
}) => {
  return (
    <input
      className={`onyx-input ${className ? className : ""}`}
      style={style}
      value={value}
      name={name}
      type={type}
      placeholder={placeholder}
      tabIndex={tabIndex}
      onChange={onChange}
    />
  );
};

export default InputText;
