import ReduxState, { PlayerStats } from "../../../redux/state";
import { connect } from "react-redux";
import { pick } from "lodash";
import React from "react";
import { getPlayerPicture } from "../../../helpers/match.helpers";
import peopleImg from "../../../assets/people.png"
import { getStorageValue } from "../../../helpers/storage.helpers";

interface PlayerStatsPicturesOwnProps {
  player: "home" | "away";
  homePlayerColor?: string;
  awayPlayerColor?: string;
}

interface PlayerStatsPicturesStore {
  stats: PlayerStats;
}

type PlayerStatsPicturesProps = PlayerStatsPicturesOwnProps &
  PlayerStatsPicturesStore;

const connector = (
  state: ReduxState,
  props: PlayerStatsPicturesOwnProps
): PlayerStatsPicturesStore => {
  if (props.player === "home") {
    return { stats: state.homeStats };
  }
  return { stats: state.awayStats };
};

const withStore = connect(connector);

export default withStore(function PlayerStatsPictures(
  props: PlayerStatsPicturesProps
) {
  const PrimaryColor = getStorageValue("sponsor-color-primary");
  const SecondaryColor = getStorageValue("sponsor-color-secondary");
  const homePlayerColor = PrimaryColor.length < 20 ? `${PrimaryColor}`: '#D21E43';
  const awayPlayerColor = SecondaryColor.length < 20 ? `${SecondaryColor}` : '#075B9A';

  const innerStyleHome = {
    border: `4px solid ${homePlayerColor}`
  };

  const innerStyleAway = {
    border: `4px solid ${awayPlayerColor}`
  };

  const src = getPlayerPicture(props.stats);
  if (src) { return <img alt={props.player} src={src} style={props.player === "home" ? { ...innerStyleAway } : { ...innerStyleHome }}/> } else {
    return <img alt={props.player} src={peopleImg} style={props.player === "home" ? { ...innerStyleAway } : { ...innerStyleHome }} />;
  }

});
