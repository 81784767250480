import React, {useState} from "react";
import qs from "qs";
import {useHistory} from "react-router";
import {
    BrowserRouter as Router,
    NavLink,
    Route,
    Switch
} from "react-router-dom";
import "./index.css";
import withLabel from "../../../helpers/withLabel";
import {makeRequest} from "../../../Utils/request";
import {persistUser} from "../../../Utils/persist";
import Button from "../../../UI/Button";
import InputText from "../../../UI/InputText";
import Label from "../../../UI/Label";
import {Login} from "../Login";

export const Register = ({onSuccess}) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [age, setAge] = useState("");
    const [gender, setGender] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    const query = qs.parse(window.location.search, {ignoreQueryPrefix: true});
    const path = query.back ? `${query.back}` : "/login";

    const onRegister = async () => {
        if (email && password) {
            setIsLoading(true)
            const data = await makeRequest("post", "/auth/register", {
                firstName: firstName,
                lastName: lastName,
                phone: phone,
                gender: gender,
                age: age,
                email: email,
                password: password,
            });
            setIsLoading(false)
            if (data) {
                await persistUser(data);
                // await onSuccess();
                await history.push(path);
            }
        }
    };

    return (
        <div className="container">
            <div className="content-register">
                <div className="bg-image"/>
                <div className="form-block">
                    <div className="title-block">
                        <Label>Register</Label>
                        <div className="logo"/>
                    </div>
                    <div className="input-block">
                        {withLabel(
                            <InputText
                                value={firstName}
                                type="firstName"
                                onChange={(e) => setFirstName(e.target.value)}
                                className="register-input"
                                placeholder=""
                            />,
                            "FirstName",
                            true
                        )}
                        {withLabel(
                            <InputText
                                value={lastName}
                                type="lastName"
                                onChange={(e) => setLastName(e.target.value)}
                                className="register-input"
                                placeholder=""
                            />,
                            "LastName",
                            true
                        )}
                    </div>
                    <div className="input-block">
                        {withLabel(
                            <InputText
                                value={phone}
                                type="phone"
                                onChange={(e) => setPhone(e.target.value)}
                                className="register-input"
                                placeholder=""
                            />,
                            "Phone",
                            true
                        )}
                        {withLabel(
                            <InputText
                                value={age}
                                type="age"
                                onChange={(e) => setAge(e.target.value)}
                                className="register-input"
                                placeholder=""
                            />,
                            "Age",
                            true
                        )}
                    </div>
                    <div className="input-block">
                        {withLabel(
                            <InputText
                                value={email}
                                type="email"
                                onChange={(e) => setEmail(e.target.value)}
                                className="register-input"
                                placeholder=""
                            />,
                            "Email",
                            true
                        )}
                        {withLabel(
                            <InputText
                                value={gender}
                                type="gender"
                                onChange={(e) => setGender(e.target.value)}
                                className="register-input"
                                placeholder=""
                            />,
                            "Gender",
                            true
                        )}
                    </div>
                    <div className="input-block">
                        {withLabel(
                            <InputText
                                value={password}
                                type="password"
                                onChange={(e) => setPassword(e.target.value)}
                                className="register-input"
                                placeholder=""
                            />,
                            "Password",
                            true
                        )}
                    </div>
                    <div className="wrap-btn">
                        <Button primary onClick={onRegister}>
                            {isLoading ? 'Loading...' : 'Register'}
                        </Button>
                    </div>
                    <div className="question-block">
                        <span>
                            Do you have an account yet?
                        </span>
                        <Router>
                            <Switch>
                                <Route path="/login" component={Login}/>
                                <NavLink to="/login">
                                    Sign in
                                </NavLink>
                            </Switch>
                        </Router>
                    </div>
                </div>
            </div>
        </div>
    )
};
