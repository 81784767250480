import React, { useState } from "react";
import { motion } from "framer-motion";

import "./index.css";
import ReduxState from "../../../redux/state";
import { getMatchOpponents } from "../../../helpers/match";
import { connect } from "react-redux";
import { getStorageValue } from "../../../helpers/storage.helpers";

interface PlayerStatsNamesStore {
  home: string;
  away: string;
}

interface PlayerStatsNamesProp {
  defScaleHidden: number | boolean;
  handleCloseStats: any;
}

type PlayerStatsNamesProps = PlayerStatsNamesStore & PlayerStatsNamesProp;

const connector = (state: ReduxState): PlayerStatsNamesStore => {
  const { match } = state;
  if (!match) {
    return {
      home: "PLAYER1",
      away: "PLAYER2",
    };
  }
  const [home, away] = getMatchOpponents(match.name);
  return { home, away };
};

function PlayerStatsNames(props: PlayerStatsNamesProps) {
  const { home, away, defScaleHidden, handleCloseStats } = props;
  const backgroundColor = getStorageValue("sponsor-color-background");
  const backgroundGradient = `linear-gradient(180deg, ${backgroundColor} 0%, ${backgroundColor} 100%)`;
  const PrimaryColor = getStorageValue("sponsor-color-primary");
  const SecondaryColor = getStorageValue("sponsor-color-secondary");
  const homePlayerColor = `${PrimaryColor}`;
  const awayPlayerColor = `${SecondaryColor}`;
 
  const innerStyleHome = {
    background: homePlayerColor,
  };

  const innerStyleAway = {
    background: awayPlayerColor,
  };

  const container = {
    hidden: { opacity: 0, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delay: 3,
        duration: 1,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  return (
    <>
      <motion.div
        className="onyx-player-stats-player-name"
        variants={container}
        initial="hidden"
        // animate="visible"
        animate={defScaleHidden ? "hidden" : "visible"}
        style={{ justifyContent: "flex-end", ...innerStyleHome, borderRadius: '0px 20px 20px 0px'}}
      >
        {home}
      </motion.div>
      <motion.div
        className="onyx-player-stats-player-name"
        variants={container}
        initial="hidden"
        // animate="visible"
        animate={defScaleHidden ? "hidden" : "visible"}
        style={{...innerStyleAway, right: '0', borderRadius: '20px 0px 0px 20px' }}
      >
        {away}
      </motion.div>
    </>
  );
}

export default connect(connector)(PlayerStatsNames);
