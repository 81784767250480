import React, {useState} from "react";
import qs from "qs";
import {useHistory} from "react-router";
import {BrowserRouter as Router, NavLink, Route, Switch} from 'react-router-dom'
import "./index.css";
import withLabel from "../../../helpers/withLabel";
import {makeRequest} from "../../../Utils/request";
import {persistUser} from "../../../Utils/persist";
import Button from "../../../UI/Button";
import InputText from "../../../UI/InputText";
import Label from "../../../UI/Label";
import {Register} from "../Registration";

export const Login = ({onSuccess}) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    const query = qs.parse(window.location.search, {ignoreQueryPrefix: true});
    const path = query.back ? `${query.back}` : "/app";

    const onLogin = async () => {
        if (email && password) {
            setIsLoading(true)
            const data = await makeRequest("post", "/auth/login", {
                email: email,
                password: password,
            });
            setIsLoading(false)
            if (data.user) {
                await persistUser(data);
                // await onSuccess();
                //  history.push(path);
                 history.goBack();
            }
        }
    };

    return (
        <div className="container">
            <div className="content">
                <div className="bg-image"/>
                <div className="form-block">
                    <div className="title-block">
                        <Label>Login</Label>
                        <div className="logo"/>
                    </div>
                    {withLabel(
                        <InputText
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder=""
                        />,
                        "Email"
                    )}
                    {withLabel(
                        <InputText
                            value={password}
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder=""
                        />,
                        "Password"
                    )}
                    <Button primary onClick={onLogin}>
                        {isLoading ? 'Loading...' : 'Login'}
                    </Button>
                    <div className="question-block">
                        <span>
                            Don't have an account yet?
                        </span>
                        <Router>
                            <Switch>
                                <Route path="/register" component={Register} />
                                <NavLink to="/register">
                                    Sign up
                                </NavLink>
                            </Switch>
                        </Router>
                    </div>
                </div>
            </div>
        </div>
    )
};
