/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import SwiperCore, { Navigation } from "swiper";
import { connect } from "react-redux";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";

import "./App.css";
import Hook from "./MouseMove";
import Appbar from "./components/layout/Appbar";
import Features from "./components/layout/Features";
import Competitions from "./components/layout/Competitions";
import LiveOddsTracker from "./components/layout/LiveOddsTracker";
import Player from "./components/layout/Player";
import { getMatchAndSponsorAction } from "./redux/actions";
import PlayersStats from "./components/layout/PlayersStats";
import {
  GIF_DISPLAY_TIME,
  GIF_DISPLAY_INTERVAL,
  GIF_FIRST_DISPLAY_INTERVAL,
} from "./config";
import SmallCharts from "./components/data-visualisation/SmallCharts";
import SmallLogoAndTime from "./components/data-visualisation/SmallLogoAndTime";
import ChartsMenu from "./components/layout/ChartsMenu";
import leftSideImg from "./assets/RightImg.png"
import rightSideImg from "./assets/LeftImg.png"
import mensImg from "./assets/mens.png"
import { getStorageValue } from './helpers/storage.helpers';

SwiperCore.use([Navigation]);

const withStore = connect(null, { getMatchAndSponsorAction });

function App(props) {
  const [names] = useState(["", ""]);
  const [loading, setLoading] = useState(true);
  const [hovered, setMouse] = useState(false);
  const [isBigWidget, setIsBigWidget] = useState(true);
  const [step, setStep] = useState(1);
  const [showSmallCharts, setShowSmallCharts] = useState(false);
  const [toggleConvertOdds, setToggleConvertOdds] = useState(false);
  const [toggleZoomOdds, setToggleZoomOdds] = useState(false);
  const [infoToggle, setInfoToggle] = useState(false);
  const [statToggle, setStatToggle] = useState(false);

  const [displayIntro, setDisplayIntro] = useState(false);
  const [firstDisplayIntro, setFirstDisplayIntro] = useState(true);
  const handleMouseEnter = () => {
    setMouse(true);
  };
  const backgroundColor = getStorageValue('sponsor-color-background');
  const backgroundGradient = `${backgroundColor}`;
  const innerStyle = {
    background: backgroundGradient,
  };

  const handleMouseLeave = () => {
    setMouse(false);
  };

  // useEffect(() => {
  //   if (step < 1) {
  //     const t = setTimeout(() => {
  //       setStep(1);
  //     }, 20000);
  //     return () => clearTimeout(t);
  //   }
  // }, [step]);

  useEffect(() => {
    const letsTry = async () => {
      setLoading(true);

      props.getMatchAndSponsorAction();

      setLoading(false);
    };

    letsTry();
  }, [props]);

  useEffect(() => {
    !firstDisplayIntro && setDisplayIntro(false);
  }, [firstDisplayIntro]);

  useEffect(() => {
    displayIntro &&
      setTimeout(() => {
        firstDisplayIntro && setFirstDisplayIntro(false);
        !firstDisplayIntro && setDisplayIntro(false);
      }, GIF_DISPLAY_TIME);
    !displayIntro &&
      setTimeout(
        () => {
          setDisplayIntro(true);
        },
        firstDisplayIntro ? GIF_FIRST_DISPLAY_INTERVAL : GIF_DISPLAY_INTERVAL
      );
  }, [displayIntro]);

  const handleStatToggle = (props) => {
    setStatToggle(true)
  }
  const handleInfoToggle = (props) => {
    setInfoToggle(true)
  }
  return (
    <>
      <Appbar />
      <Hook
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
      >
        <main className="page-main">
          {displayIntro && (
            <div className="page-main-video-intro">
              <img src={localStorage.getItem("onyx-sponsor-video-intro")} />
            </div>
          )}
          <section className="video-container">
            <h2 className="video-container-title">Boxing</h2>
            <div className="App">
              <div className="App_Left-side">
                <img src={leftSideImg} style={{ width: '100%' }} />
              </div>
              <div className="onyx-video-player">
                <Player />
                {step === 0 && <PlayersStats setStep={setStep} step={step} names={names} toggleConvertOdds={toggleConvertOdds} setInfoToggle={setInfoToggle} setStatToggle={setStatToggle}/>}
                {step === 1 && (
                  <>
                    {!loading && (
                    // {!loading && statToggle && (
                      <LiveOddsTracker
                        names={names}
                        hovered={hovered}
                        step={step}
                        setStep={setStep}
                        setIsBigWidget={setIsBigWidget}
                        isBigWidget={isBigWidget}
                        toggleConvertOdds={toggleConvertOdds}
                        setToggleConvertOdds={setToggleConvertOdds}
                        toggleZoomOdds={toggleZoomOdds}
                        statToggle={statToggle}
                        setStatToggle={setStatToggle}
                        setToggleZoomOdds={setToggleZoomOdds}
                      />
                    )}
                    {/* <div className='App_info-stat_buttons'>
                      <div className='App_Left-side_stat-button' onClick={handleStatToggle}>
                        <svg width="34" height="28" viewBox="0 0 34 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g filter="url(#filter0_d)">
                            <path d="M18.7668 21.6457H21.071C21.4551 21.6457 21.8391 21.2604 21.8391 20.8751V12.7831C21.8391 12.3977 21.4551 12.0124 21.071 12.0124H18.7668C18.3828 12.0124 17.9987 12.3977 17.9987 12.7831V20.8751C17.9987 21.2604 18.3828 21.6457 18.7668 21.6457ZM24.5273 21.6457H26.8316C27.2156 21.6457 27.5996 21.2604 27.5996 20.8751V7.00306C27.5996 6.61772 27.2156 6.23239 26.8316 6.23239H24.5273C24.1433 6.23239 23.7593 6.61772 23.7593 7.00306V20.8751C23.7593 21.2604 24.1433 21.6457 24.5273 21.6457ZM7.24573 21.6457H9.54995C9.93398 21.6457 10.318 21.2604 10.318 20.8751V16.6364C10.318 16.2511 9.93398 15.8657 9.54995 15.8657H7.24573C6.8617 15.8657 6.47766 16.2511 6.47766 16.6364V20.8751C6.47766 21.2604 6.8617 21.6457 7.24573 21.6457ZM13.0063 21.6457H15.3105C15.6945 21.6457 16.0786 21.2604 16.0786 20.8751V8.92973C16.0786 8.54439 15.6945 8.15906 15.3105 8.15906H13.0063C12.6222 8.15906 12.2382 8.54439 12.2382 8.92973V20.8751C12.2382 21.2604 12.6222 21.6457 13.0063 21.6457Z" fill="white" />
                          </g>
                          <defs>
                            <filter id="filter0_d" x="0.769019" y="0.523749" width="32.5393" height="26.8306" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                              <feFlood flood-opacity="0" result="BackgroundImageFix" />
                              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                              <feOffset />
                              <feGaussianBlur stdDeviation="2.85432" />
                              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                            </filter>
                          </defs>
                        </svg>
                      </div>
                      <div className='App_Left-side_info-button' onClick={handleInfoToggle}>
                        <svg width="9" height="22" viewBox="0 0 9 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1.16045 17.9059H1.99296V12.1075H1.16045C0.700657 12.1075 0.327942 11.7481 0.327942 11.3048V9.38728C0.327942 8.94391 0.700657 8.58451 1.16045 8.58451H5.82251C6.28231 8.58451 6.65502 8.94391 6.65502 9.38728V17.9059H7.48753C7.94733 17.9059 8.32004 18.2653 8.32004 18.7087V20.6262C8.32004 21.0695 7.94733 21.4289 7.48753 21.4289H1.16045C0.700657 21.4289 0.327942 21.0695 0.327942 20.6262V18.7087C0.327942 18.2653 0.700657 17.9059 1.16045 17.9059ZM4.32399 0.877838C2.66875 0.877838 1.32695 2.17172 1.32695 3.76784C1.32695 5.36396 2.66875 6.65784 4.32399 6.65784C5.97923 6.65784 7.32103 5.36396 7.32103 3.76784C7.32103 2.17172 5.97919 0.877838 4.32399 0.877838Z" fill="white" />
                        </svg>
                      </div>
                    </div>
                    <div style={innerStyle} className="back-to-players_button" onClick={() => setStep(0)}>
                      <img className="back-to-players_button-img" src={mensImg} alt='mensImg'/>
                    </div> */}
                  </>
                )}
                {!loading && <SmallCharts names={names} toggleConvertOdds={toggleConvertOdds} setInfoToggle={setInfoToggle} infoToggle={infoToggle}/>}
                {/* {!loading && infoToggle && <SmallCharts names={names} toggleConvertOdds={toggleConvertOdds} setInfoToggle={setInfoToggle} infoToggle={infoToggle}/>} */}
                <SmallLogoAndTime 
                  names={names} 
                  toggleConvertOdds={toggleConvertOdds} 
                  setInfoToggle={setInfoToggle} 
                  statToggle={statToggle}
                  setStatToggle={setStatToggle}
                  infoToggle={infoToggle}
                  setStep={setStep}
                  step={step}
                />
                <ChartsMenu
                  isBigWidget={isBigWidget}
                  setStep={setStep}
                  setIsBigWidget={setIsBigWidget}
                  showSmallCharts={showSmallCharts}
                  setShowSmallCharts={setShowSmallCharts}
                  toggleConvertOdds={toggleConvertOdds}
                  setToggleConvertOdds={setToggleConvertOdds}
                  toggleZoomOdds={toggleZoomOdds}
                  setToggleZoomOdds={setToggleZoomOdds}
                />
              </div>
              <div className="App_Right-side">
                <img src={rightSideImg} style={{ width: '100%' }} />
              </div>
            </div>
          </section>
          {/* <section className="competitions-container">
            <button
              className="switch-button"
              onClick={() => {
                setIsBigWidget(true);
              }}
            />
            <button
              className="switch-button switch-button--green"
              onClick={() => {
                setIsBigWidget(false);
              }}
            />

            <h2 className="video-container-title">Features</h2>

            <Features />
          </section>
          <section className="features-container">
            <h2 className="video-container-title">Competitions</h2>
            <Competitions />
          </section> */}
        </main>
      </Hook>
    </>
  );
}
export default withStore(App);
