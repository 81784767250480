import {
  VictoryLine,
  VictoryScatter,
  VictoryGroup,
  VictoryLabel,
  VictoryChart,
  VictoryAxis
} from "victory";
import React from "react";
import ReduxState from "../../../redux/state";
import { map, max, last, get, min } from "lodash";
import { connect } from "react-redux";
import LastOddsDot from "../LastOddsDot";
import { getOddDirection } from "../../../helpers/odds.helpers";
import { MAX_ODDS_X, TICK_INTERVAL } from "../../../config";
const oddsConverter = require('odds-converter')
interface OddsLineOwnProps {
  player: "home" | "away";
  color: string;
  toggleConvertOdds: boolean;
  toggleZoomOdds: boolean;
}
interface OddsLineStore {
  // values: number[];
  valuesHome: number[];
  valuesAway: number[];
}

type OddsLineProps = OddsLineOwnProps & OddsLineStore;

const connector = (
  state: ReduxState,
  props: OddsLineOwnProps
): OddsLineStore => ({ valuesHome: map(state.odds, 'home'), valuesAway: map(state.odds, 'away') });

export default connect(connector)(function OddsLine(props: OddsLineProps) {
  const { valuesHome, valuesAway, color, toggleConvertOdds, toggleZoomOdds } = props;
  
  const dataHome = valuesHome.map((y, x) => ({ x, y }));
  const dataAway = valuesAway.map((y, x) => ({ x, y }));
  const [ready, setReady] = React.useState(false);
  
  const bothArrays = dataAway.concat(dataHome)
  const yMin = bothArrays.reduce((acc, el) => {
    return Math.min(acc, el.y)
  }, 0)

  const yMax = bothArrays.reduce((acc, el) => {
    return Math.max(acc, el.y)
  }, 0)

  // const resultYinterval = Math.abs(yMin) > Math.abs(yMax) ? Math.abs(yMin) : Math.abs(yMax);
  // console.log('resultYinterval', resultYinterval)

  // const WIDTH = window.innerWidth - 240;
  const WIDTH = window.innerWidth;
  // const WIDTH = 100%;
  // const WIDTH = window.innerWidth - 490;

  const MAX = 10 * (TICK_INTERVAL / 500);
  // 2 hours
  // MAX TIME
  // const BOXING_ROUND = 1000 * 60 * 60 * 2;

// TICK_INTERVAL = 1500;

// const BOXING_ROUND = 1000 * 60;

  const addHome = dataHome.length * 1.5;
  const addAway = dataAway.length * 1.5;
  const resultAdd = addHome > addAway ? addHome : addAway;

  const BOXING_ROUND = 1000 * resultAdd;
  const INTERVALS = BOXING_ROUND / TICK_INTERVAL;

  if (!ready) {
    setTimeout(() => {
      setReady(true);
    }, 1500);
  }

  if (dataHome.length < 2 && dataAway.length < 2) {
    return <span />;
  }

  const nextDataHome = [...dataHome].slice(0, INTERVALS);
  const nextDataAway = [...dataAway].slice(0, INTERVALS);

  const lastXHome = nextDataHome.length - 1;
  const lastYHome = get(nextDataHome[nextDataHome.length - 1], "y");


  const lastXAway = nextDataAway.length - 1;
  const lastYAway = get(nextDataAway[nextDataAway.length - 1], "y");
  let emojiHome = "";
  let emojiAway = "";

  const dirHome = getOddDirection(lastYHome);
  const dirAway = getOddDirection(lastYAway);

  // if (dir === '+') {
  //   emoji = '▲';
  // } else {
  //   emoji = '▼';
  // }

  // TODO
  const setEmojiHome = () => {
    if (nextDataHome.length > 1) {
      const lastY = nextDataHome[nextDataHome.length - 1].y;
      const preLastY = nextDataHome[nextDataHome.length - 2].y;

      if (lastY > preLastY) {
        emojiHome = "▲";
      } else if (lastY === preLastY) {
        emojiHome = "";
      } else {
        emojiHome = "▼";
      }
    } else if (nextDataHome.length === 1) {
      if (dirHome === "+") {
        emojiHome = "▲";
      } else {
        emojiHome = "▼";
      }
    } else {
      emojiHome = "";
    }
  };

  setEmojiHome();

  const setEmojiAway = () => {
    if (nextDataAway.length > 1) {
      const lastY = nextDataAway[nextDataAway.length - 1].y;
      const preLastY = nextDataAway[nextDataAway.length - 2].y;
      if (lastY > preLastY) {
        emojiAway = "▲";
      } else if (lastY === preLastY) {
        emojiAway = "";
      } else {
        emojiAway = "▼";
      }
    } else if (nextDataAway.length === 1) {
      if (dirAway === "+") {
        emojiAway = "▲";
      } else {
        emojiAway = "▼";
      }
    } else {
      emojiAway = "";
    }
  };

  setEmojiAway();

  const labelProps = {
    dx: 20,
    textAnchor: "start",
    verticalAnchor: "end",
  };

  if (lastXHome === INTERVALS - 1 && lastXAway === INTERVALS - 1) {
    labelProps.dx = -120;
    // @ts-ignore
    labelProps.dy = -20;
  }

  const reverseDataHome = () => nextDataHome.map((el: any) => ({ x: el.x, y: el.y }));
  const reverseDataAway = () => nextDataAway.map((el: any) => ({ x: el.x, y: el.y }));
  const dataLineHome = toggleZoomOdds ? reverseDataHome().slice(Math.max(reverseDataHome().length - 10, 1)) : reverseDataHome()
  const dataLineAway = toggleZoomOdds ? reverseDataAway().slice(Math.max(reverseDataAway().length - 10, 1)) : reverseDataAway()

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <VictoryChart
        maxDomain={{ x: INTERVALS, y: yMax * 1.5}}
        minDomain={{ y: yMin * 5}}
        width={WIDTH}
      >
        <VictoryAxis style={{
          axis: { stroke: "transparent" },
          ticks: { stroke: "transparent" },
          tickLabels: { fill: "transparent" }
        }} />
        <VictoryGroup
          // height={150}
          // width={WIDTH}
          // maxDomain={{ x: INTERVALS }}
        // @ts-ignore
        >
          <VictoryLine
            data={dataLineHome}
            interpolation="natural"
            style={{
              data: {
                stroke: '#DD1A37',
                strokeWidth: 10,
              },
            }}
            animate={{
              duration: 10000,
              onLoad: { duration: 3000 },
              onEnter: { duration: 10000 },
              onExit: { duration: 10000 },
            }}
          />
          {ready && (
            <VictoryScatter
              style={{
                data: { fill: "red", stroke: "white", strokeWidth: 2, display: 'none' },
              }}
              size={7}
              
              data={[{ x: lastXHome, y: lastYHome }]}
              // ${emojiHome}
              labels={({ datum }) => toggleConvertOdds ? `${dirHome}${(oddsConverter.american.toDecimal(datum.y) * 1.00000000000001).toFixed(2)}` : `${dirHome}${datum.y}`}
              labelComponent={
                // @ts-ignore
                <VictoryLabel {...labelProps} dy={lastYHome > lastYAway ? -100 : 100} style={lastYHome !== lastYAway ? { fill: "white", fontSize: '50px' } : {display: 'none'}} />
              }
            />
          )}
        </VictoryGroup>
        <VictoryGroup
          // height={150}
          // width={WIDTH}
          // maxDomain={{ x: INTERVALS }}
        // @ts-ignore
        >
          <VictoryLine
            data={dataLineAway}
            interpolation="natural"
            style={{
              data: {
                stroke: '#045C9B',
                strokeWidth: 10,
              },
            }}
            animate={{
              duration: 10000,
              onLoad: { duration: 3000 },
              onEnter: { duration: 10000 },
              onExit: { duration: 10000 },
            }}
          />
          {ready && (
            <VictoryScatter
              style={{
                data: { fill: "red", stroke: "white", strokeWidth: 2, display: 'none' },
              }}
              size={7}
              data={[{ x: lastXAway, y: lastYAway }]}
              // ${emojiAway}
              labels={({ datum }) => toggleConvertOdds ? `${dirAway}${(oddsConverter.american.toDecimal(datum.y) * 1.00000000000001).toFixed(2)}` : `${dirAway}${datum.y}`}
              labelComponent={
                // @ts-ignore
                <VictoryLabel dy={lastYAway > lastYHome ? -100 : 100} {...labelProps} style={{ fill: "white", fontSize: '50px' }} />
              }
            />
          )}
        </VictoryGroup>
      </VictoryChart>
    </div>
  );
});

